
import { green, red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    fontSize: 16,
  },
  palette: {
    primary: {
      main: '#00a8db',
    },
    secondary: {
      main: green[400],
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#272723',
    },
  },
});


export default theme;
